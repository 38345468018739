
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination } from "swiper";
import Link from "next/link";
import 'swiper/css';
import 'swiper/css/pagination';
const MainFeatures = ({ coin, main_features }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState({});
    const { social_trading, exchange, governance_and_staking, nft_marketplace } = main_features.items;
    return (<section className='main_features' id={'features'}>
            <div className="container">
                <div className="main_features_wrapper">
                    {coin ? <h2 className='main_features_title'>Option<span style={{ textDecoration: 'underline' }}>2</span>Trade {main_features.title}</h2> :
            <h2 className='main_features_title'>{main_features.title}</h2>}
                    {coin ? <p className='main_features_description'>Option<span style={{ textDecoration: 'underline' }}>2</span>Trade {main_features.description}</p> :
            <p className='main_features_description'>{main_features.description}</p>}
                    <div className='slider_buttons_wrapper'>
                        <ul className='slider_buttons'>
                            {main_features.slider_buttons.map((el, i) => {
            return <li className={`slider_buttons_button ${activeSlide === i ? 'active' : ''}`} key={el.id}>
                                    <button onClick={() => {
                    swiperInstance.slideTo(i);
                }}>{el.title}</button>
                                </li>;
        })}
                        </ul>
                    </div>
                    <div className='features_swiper_wrapper'>
                        <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} keyboard={{
            enabled: true,
            onlyInViewport: true
        }} onSlideChange={(e) => {
            setActiveSlide(e.activeIndex);
        }} initialSlide="-1" centeredSlides={false} grabCursor={true} cssMode={true} spaceBetween={10} mousewheel={true} autoplay={true} navigation={{ clickable: true }} breakpoints={{
            576: {
                slidesPerView: 1
            }
        }} lazy={false} modules={[Navigation, Pagination, Keyboard]} slidesPerView={1}>
                            {/*social_trading*/}
                            <SwiperSlide>
                                <div className='features_slider_slide'>
                                    <div className='slide_media'>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/social_trading_chat.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/social_trading_chat_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/social_trading_chat.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/social_trading_chat_x2.png`}/>
                                            <img style={{ width: '50%' }} src={`/img/pages/why_o2t/features/social_trading_chat.png`} alt="Option2Trade Social Trading" width={263} height={264} className='slide_media_chat'/>
                                        </picture>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/social_trading.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/social_trading_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/social_trading.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/social_trading_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/social_trading.png`} alt="Option2Trade Social Trading" width={530} height={256} className='slide_media_img'/>
                                        </picture>
                                    </div>
                                    <div className='slide_description'>
                                        <div className='slide_description_title'>
                                            <h3 className='slide_description_title_title'>{social_trading.title}</h3>
                                        </div>
                                        <div className='slide_description_text'>
                                            <p className='slide_description_text_text'>
                                                {social_trading.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/*exchange*/}
                            <SwiperSlide>
                                <div className='features_slider_slide'>
                                    <div className='slide_media'>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/ex_chat.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/ex_chat_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/ex_chat.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/ex_chat_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/ex_chat.png`} alt="Option2Trade High percentage payouts No Commission" width={471} height={471} className='slide_media_chat'/>
                                        </picture>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/ex.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/ex_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/ex.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/ex_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/zero_commission.png`} alt="Option2Trade High percentage payouts No Commission" width={540} height={256} className='slide_media_img'/>
                                        </picture>
                                    </div>
                                    <div className='slide_description'>
                                        <div className='slide_description_title'>
                                            <h3 className='slide_description_title_title'>{exchange.title}</h3>
                                        </div>
                                        <div className='slide_description_text'>
                                            <p className='slide_description_text_text'>{exchange.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/*governance_and_staking*/}
                            <SwiperSlide>
                                <div className='features_slider_slide'>
                                    <div className='slide_media'>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/gs_chat.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/gs_chat_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/gs_chat.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/gs_chat_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/gs_chat.png`} alt="Option2Trade Multi Asset" width={441} height={496} className='slide_media_chat multi_asset'/>
                                        </picture>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/gs.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/gs_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/gs.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/gs_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/gs.png`} alt="Option2Trade Multi Asset" width={530} height={256} className='slide_media_img'/>
                                        </picture>
                                    </div>
                                    <div className='slide_description'>
                                        <div className='slide_description_title'>
                                            <h3 className='slide_description_title_title'>{governance_and_staking.title}</h3>
                                        </div>
                                        <div className='slide_description_text'>
                                            <p className='slide_description_text_text'>{governance_and_staking.description}

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/*nft_marketplace*/}
                            <SwiperSlide>
                                <div className='features_slider_slide'>
                                    <div className='slide_media'>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_chat.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_chat_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_chat.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_chat_x2.png`}/>
                                            <img style={{ width: '50%' }} src={`/img/pages/why_o2t/features/nft_mkp_chat.png`} alt="Option2Trade Learn and Earn" width={262} height={247} className='slide_media_chat'/>
                                        </picture>
                                        <picture>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/nft_mkp.webp`} type="image/webp"/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_x2.webp`} type="image/webp"/>
                                            <source media="(max-width: 576px)" srcSet={`/img/pages/why_o2t/features/nft_mkp.png`}/>
                                            <source media="(min-width: 577px)" srcSet={`/img/pages/why_o2t/features/nft_mkp_x2.png`}/>
                                            <img src={`/img/pages/why_o2t/features/nft_mkp.png`} alt="Option2Trade Learn and Earn" width={530} height={256} className='slide_media_img'/>
                                        </picture>
                                    </div>
                                    <div className='slide_description'>
                                        <div className='slide_description_title'>
                                            <h3 className='slide_description_title_title'>{nft_marketplace.title}</h3>
                                        </div>
                                        <div className='slide_description_text'>
                                            <p className='slide_description_text_text'>
                                                {nft_marketplace.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>);
};
export default MainFeatures;

    async function __Next_Translate__getStaticProps__190081425c9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//why_o2t/MainFeatures/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081425c9__ as getStaticProps }
  