import React, {useState} from 'react';

const AccordionItem = ({item, index, activeIndex, setActiveIndex}) => {

    return <li
        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay="0"
    >
        <div
            onClick={(e) => {
                if (!activeIndex || activeIndex !== index) {
                    setActiveIndex(index)
                } else {
                    setActiveIndex(null)
                }
            }}
            className={`accordion_item_wrapper ${activeIndex === index ? 'is_active' : ''}`}
        >
            <div className='accordion_item'>
                <h3 className='accordion_item_title'>{item.title}</h3>
                <span className='accordion_item_icon'>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`accordion_item_body`}>
                <p>{item.body.text}</p>
                {item.body.list ? <ol>
                    {item.body.list.map(item => {
                        return <li key={item}>{item}</li>
                    })}
                </ol> : null}
                {item.body.long_list ?
                    item.body.long_list.map(item => {
                        return <div key={item.key}>
                            {item.title ? <h4 className='bold'>{item.title}</h4> : null}
                            {item.text ? <p>{item.text}</p> : null}
                            {item.text_1 ? <p>{item.text_1}</p> : null}
                            <ol>
                                {item.list.map(item => {
                                    return <li key={item}>{item}</li>
                                })}
                            </ol>
                            {item.text_2 ? <p>{item.text_2}</p> : null}
                            {item.list_1 ? <ol>
                                {item.list_1.map(item => {
                                    return <li key={item}>{item}</li>
                                })}
                            </ol> : null}
                            {item.text_3 ? <p>{item.text_3}</p> : null}
                        </div>
                    })
                    : null}
                {item.body.text_1 ? <p>{item.body.text_1}</p> : null}
                {item.body.text_2 ? <p>{item.body.text_2}</p> : null}
                {item.body.text_3 ? <p>{item.body.text_3}</p> : null}
                {item.body.list_1 ? <ol>
                    {item.body.list_1.map(item => {
                        return <li key={item}>{item}</li>
                    })}
                </ol> : null}
                {item.body.text_4 ? <p>{item.body.text_4}</p> : null}
            </div>
        </div>
    </li>
}

const Accordion = ({faqs_list}) => {

    const [activeIndex, setActiveIndex] = useState(null)

    return (
        <div className='accordion_container'>
            <ul className='accordion_data'>
                {faqs_list.map((item) => {
                    return <AccordionItem
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={item.key}
                        key={item.key}
                        item={item}
                    />
                })}
            </ul>
        </div>
    );
};

export default Accordion;