
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import { getCookie } from "../../../../helpers/cookies";
const Tokenomics = ({ t, doc }) => {
    const tokenomics = t('coin:tokenomics', {}, { returnObjects: true });
    return (<section className='tokenomisc_section' id='tokenomics'>
            <div className="container">
                <div className='tokenomisc_section_wrapper'>
                    <div className={`tokenomisc_section_media`}>
                        <div className="total">
                            <h3 className='total_title'>{tokenomics.amount}</h3>
                            <span className='total_value'>624,888,777</span>
                            <picture>
                                <source srcSet={`/img/pages/coin/tokenomics/o2t.webp`} type="image/webp"/>
                                <source srcSet={`/img/pages/coin/tokenomics/o2t.png`}/>
                                <img src={`/img/pages/coin/tokenomics/o2t.png`} alt="Join Option2Trade" width={112} height={112} className='total_img'/>
                            </picture>
                        </div>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/coin/tokenomics/tokenomics.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/coin/tokenomics/tokenomics_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/coin/tokenomics/tokenomics.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/coin/tokenomics/tokenomics_x2.png`}/>
                            <img src={`/img/pages/coin/tokenomics/tokenomics.png`} alt="Join Option2Trade" width={620} height={618} className='media_img'/>
                        </picture>
                    </div>
                    <div className="tokenomisc_section_data">
                        <h2 className='data_title'>{tokenomics.title}</h2>
                        <ul className='data_list'>
                            {tokenomics.data.map(el => {
            return <li key={el.title} className='data_list_item'>
                                    <div className='data_list_item_title'>{el.title}</div>
                                    <div className='data_list_item_value'>{el.value}</div>
                                </li>;
        })}
                        </ul>
                        <Link className='tokenomisc_section_data_cta' prefetch={false} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                            <MainButtonBlue text={tokenomics.cta}/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>);
};
export default Tokenomics;

    async function __Next_Translate__getStaticProps__190081424da__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/Tokenomics/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424da__ as getStaticProps }
  