
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/common/CoinHeader";
import CoinFooter from "../components/common/CoinFooter";
import Loader from "../components/common/Loader";
import CoinBanner from "../components/pages/coin/Banner";
import Link from "next/link";
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";
import MainButtonBlue from "../components/common/buttons/MainButtonBlue";
import Features from "../components/pages/home/Features";
import Why from "../components/pages/home/Why";
import MainFeatures from "../components/pages/why_o2t/MainFeatures";
import CoinAbout from "../components/pages/coin/About";
import CoinBots from "../components/pages/coin/Bots";
import FeaturedIn from "../components/pages/home/FeaturedIn";
import Tokenomics from "../components/pages/coin/Tokenomics";
import CoinAudit from "../components/pages/coin/Audit";
import CoinRoadmap from "../components/pages/coin/Roadmap";
import CoinFaqs from "../components/pages/coin/Faqs";
import Disclaimer from "../components/common/Disclaimer";
import { getCookie } from "../helpers/cookies";
import Counter from "../components/common/Counter";
import axios from "axios";
export default function Coin({ title, description, canonical }) {
    const { t } = useTranslation();
    const ready_to_start = t('coin:ready_to_start', {}, { returnObjects: true });
    const features = t('coin:features', {}, { returnObjects: true });
    const why = t('coin:why', {}, { returnObjects: true });
    const seo = t('coin:seo', {}, { returnObjects: true });
    const main_features = t('coin:main_features', {}, { returnObjects: true });
    const featured_in = t('coin:featured_in', {}, { returnObjects: true });
    const top_questions = t('coin:top_questions', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);
    const [w, setWindow] = useState(undefined);
    const [doc, setDoc] = useState(undefined);
    const [apiData, setApiData] = useState({
        current_token_price: "0",
        next_token_price: "0",
        percent_tokens: "0",
        percent_usd: "0",
        stage_name: "Stage 1",
        tokens_left_by_stage: "0",
        tokens_sold: "0",
        total_tokens: "0",
        total_usd: "0",
        usd_raised: "0"
    });
    const leaveAction = () => {
        w !== undefined ? w.history.back() : void (0);
    };
    const acceptAction = () => {
        setDisclaimer(false);
        document.body.style.overflow = 'auto';
    };
    const getData = async () => {
        return await axios.get('/api/get-data', {
            headers: {
                "Content-Type": "application/json"
            }
        });
    };
    useEffect(() => {
        setWindow(window);
        setDoc(document);
        let apiKey = '';
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        let getIp = new Promise((resolve, reject) => {
            fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`)
                .then(res => {
                return res.json();
            }).then(data => {
                if (data.country_code2 === 'GB') {
                    setTimeout(() => {
                        document.body.style.overflow = 'hidden';
                        setDisclaimer(true);
                    }, 2000);
                }
                resolve();
            })
                .catch(err => {
                resolve();
            });
        });
        Promise.all([appLoading, getIp]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    return (<MainLayout title={seo.title} description={seo.description} keywords={seo.keywords} page={'index'}>
            <div className='banner_bg'>
                <section className='top_line'>
                    <div className="container">
                        <div className="top_line_wrapper">
                            <h2>
                                You Can Now Claim Your O2T Tokens
                                <a className={'top_buy'} href={'https://claims.option2tradepresale.com/'} target={'_blank'}>Claim</a>
                            </h2>
                        </div>
                    </div>
                </section>
                <Header t={t} doc={doc}/>
                <CoinBanner doc={doc} apiData={apiData} t={t}/>
            </div>
            <main className='main'>
                <Features features={features} doc={doc}/>
                <CoinAbout t={t} doc={doc}/>
                <MainFeatures coin={true} main_features={main_features} doc={doc}/>
                <FeaturedIn featured_in={featured_in} doc={doc}/>
                <CoinBots t={t} doc={doc}/>
                <Tokenomics t={t} doc={doc}/>
                <Why why={why} doc={doc}/>
                <CoinAudit t={t} doc={doc}/>
                <CoinRoadmap t={t} doc={doc}/>
                <CoinFaqs top_questions={top_questions} doc={doc}/>
                <section className='ready_to_start'>
                    <div className="container">
                        <div className='ready_to_start_wrapper'>
                            <h2 className='ready_to_start_title'>{ready_to_start.title}</h2>
                            <Link className='ready_to_start_cta' prefetch={false} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                                <MainButtonBlue style={{ padding: '18px 48px' }} text={ready_to_start.cta}/>
                            </Link>
                        </div>
                    </div>
                </section>
            </main>
            <CoinFooter t={t} doc={doc}/>
            <Loader isLoading={isLoading} loaded={loaded}/>
            <Disclaimer accept={acceptAction} leave={leaveAction} isShow={disclaimer} doc={doc}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__190081421d1__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081421d1__ as getStaticProps }
  