
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import Accordion from "../../../common/accordion";
const TopQuestions = ({ top_questions }) => {
    return (<section className='faqs_list_section'>
            <div className="container">
                <div className='faqs_list'>
                    <div className='faqs_list_category'>
                        <h3 className='faqs_list_category_title'>Top Questions</h3>
                    </div>
                    <div className='faqs_list_list'>
                        <Accordion faqs_list={top_questions?.list || []}/>
                    </div>
                </div>
            </div>
        </section>);
};
export default TopQuestions;

    async function __Next_Translate__getStaticProps__19008142e86__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//faqs/TopQuestions/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19008142e86__ as getStaticProps }
  