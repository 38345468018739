
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const CoinAudit = ({ t }) => {
    const audit = t('coin:audit', {}, { returnObjects: true });
    return (<section className='audit_section'>
            <div className="container">
                <div className="audit_section_wrapper">
                    <h3 className="audit_section_title">{audit.title}</h3>
                    {audit.items.map(item => {
            return <div className='audit_section_item' key={item.title}>
                            <img width={item.icon_w} height={item.icon_h} src={`/img/pages/coin/audit/${item.icon}.svg`} alt={`${item.title} Audit`}/>
                            <h4>{item.title}</h4>
                            <p>{item.text}<a href={item.icon === 'cyberscope' ? 'https://www.cyberscope.io/audits/option2trade' : '/'} target={'_blank'}>{item.cta}</a></p>

                        </div>;
        })}
                </div>
            </div>
        </section>);
};
export default CoinAudit;

    async function __Next_Translate__getStaticProps__190081424de__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/Audit/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424de__ as getStaticProps }
  