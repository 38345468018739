
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import { getCookie } from "../../../../helpers/cookies";
const CoinAbout = ({ t, doc }) => {
    const about = t('coin:about', {}, { returnObjects: true });
    return (<section className='coin_about how_to_section' id={'about'}>
            <div className="container">
                <div className='how_to_section_main'>
                    <div className="how_to_description">
                        <h2 className="how_to_description_title">{about.title}</h2>
                        <p className="how_to_description_description">{about.description}</p>
                        <Link className='how_to_description_cta' prefetch={false} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                            <MainButtonBlue text={about.cta}/>
                        </Link>
                    </div>
                    <div className={`how_to_media`}>
                        <picture>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/coin/about/coin_about.webp`} type="image/webp"/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/coin/about/coin_about_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 576px)" srcSet={`/img/pages/coin/about/coin_about.png`}/>
                            <source media="(min-width: 577px)" srcSet={`/img/pages/coin/about/coin_about_x2.png`}/>
                            <img src={`/img/pages/coin/about/coin_about.png`} alt="Join Option2Trade" width={620} height={617} className='how_to_media_img'/>
                        </picture>
                    </div>
                </div>
                <ul className={`how_to_section_additional`}>
                    {about.additional.map(el => {
            return <li className='how_to_section_additional_item' key={el.key}>
                            {el.title ?
                    <div className='stocks_how_to'>
                                    <img width={54} height={54} src={`/img/pages/trade/stocks/how_to/${el.icon}.svg`} alt={`Option2Trade ${el.title}`}/>
                                    <h3>{el.title}</h3>
                                    <p>{el.text}</p>
                                </div> :
                    el.text}
                        </li>;
        })}
                </ul>
            </div>
        </section>);
};
export default CoinAbout;

    async function __Next_Translate__getStaticProps__190081424ca__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/About/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424ca__ as getStaticProps }
  