
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import AppStoreButton from "../../../common/buttons/AppStoreButton";
import PlayMarketButton from "../../../common/buttons/PlayMarketButton";
const CoinBots = ({ t }) => {
    const bots = t('coin:bots', {}, { returnObjects: true });
    return (<section className='bots_section'>
            <div className="bots_section_wrapper">
                <div className="container">
                    <h2 className="bots_section_title">{bots.title}</h2>
                    <div className="bots_section_content">
                        <div className="bots_section_content_description">
                            <p className="description_text">{bots.description}</p>
                            <div className='download'>
                                <Link className='cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_STORE_URL}>
                                    <AppStoreButton />
                                </Link>
                                <Link className='cta' prefetch={false} href={process.env.NEXT_PUBLIC_PLAY_MARKET_URL}>
                                    <PlayMarketButton />
                                </Link>
                            </div>
                        </div>
                        <div className="bots_section_content_media">
                            <picture>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/coin/bots/bots.webp`} type="image/webp"/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/coin/bots/bots_x2.webp`} type="image/webp"/>
                                <source media="(max-width: 576px)" srcSet={`/img/pages/coin/bots/bots.png`}/>
                                <source media="(min-width: 577px)" srcSet={`/img/pages/coin/bots/bots_x2.png`}/>
                                <img src={`/img/pages/coin/bots/bots.png`} alt="Join Option2Trade" width={950} height={503} className='how_to_media_img'/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default CoinBots;

    async function __Next_Translate__getStaticProps__190081424d2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/Bots/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424d2__ as getStaticProps }
  