
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButtonBlue from "../../../common/buttons/MainButtonBlue";
import { numberWithCommas, numberWithCommasNull } from "../../../../helpers/numberWithCommas";
import { getCookie } from "../../../../helpers/cookies";
const CoinBanner = ({ t, apiData, doc }) => {
    const banner = t('coin:banner', {}, { returnObjects: true });
    const sale_end = t('coin:sale_end', {}, { returnObjects: true });
    const StageItem = ({ stage, isLast }) => {
        return <div style={{ left: `${stage.percent_from_total}%` }} className={`price_item ${stage.current ? 'current' : ''}`}>
            <div className={`price_item_wrapper`}>{stage.price}</div>
            {stage.current && !isLast ? <div className={'current_price'}>Current Price</div> : null}
        </div>;
    };
    return (<section className='coin_banner'>
            <div className="container">
                <div className="coin_banner_wrapper">
                    <div className="coin_banner_title">
                        <h1 className='title_title'>
                            <span>{banner.title}</span>
                            <span>{banner.title_2}</span>
                            <span>{banner.title_3} Option<span style={{ textDecoration: 'underline' }}>2</span>Trade</span>
                        </h1>
                        <div className="mobile_hidden">
                            <p className='title_description'>
                                O<span style={{ textDecoration: 'underline' }}>2</span>T
                                {banner.description}
                            </p>
                            <Link className='nav_buy_button' prefetch={false} target={'_blank'} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                                <button className='nav_buy_button'>{banner.cta}
                                    <svg className='inner_arrow' xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                        <path d="M10.2112 1L17.2112 8L10.2112 15" stroke="#191919" strokeWidth="2"/>
                                        <path d="M17.5 8.00098H0" stroke="#191919" strokeWidth="2"/>
                                    </svg>
                                </button>
                            </Link>
                        </div>
                        <ul className='title_features'>
                            {banner.features.map(el => {
            return <li className='title_features_item' key={el}>
                                    {el}
                                </li>;
        })}
                        </ul>
                    </div>
                    <div className="coin_banner_progress">
                        <div className="progress_wrapper">
                            <h2>{sale_end.title}</h2>
                            <h3>{sale_end.cex}</h3>
                            <h4>{sale_end.contract}</h4>
                            <h5>0x9Cb228d638639881155211B03b8Dc1027bf0880d</h5>
                            <div className={'progress_bottom_cta'}>
                                <Link className='progress_how' prefetch={false} target={'_blank'} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                                    <MainButtonBlue arrow text={sale_end.buy_cta}/>
                                </Link>
                                <Link className='progress_win' prefetch={false} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'} target={'_blank'}>
                                    <MainButtonBlue arrow text={sale_end.chart_cta}/>
                                </Link>
                            </div>
                            <p>{sale_end.support_1} <a href={'mailto:support@option2tradepresale.com'}>support@option2tradepresale.com</a> {sale_end.support_2}</p>
                            <div className='progress_promo_social'>
                                {banner.progress.social.map(el => {
            return <Link key={el.title} target={'_blank'} className='social_item' href={el.url} aria-label={el.title}>
                                        {el.title === 'telegram' ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M20.3333 10C20.3333 15.5228 15.8562 20 10.3333 20C4.8105 20 0.333344 15.5228 0.333344 10C0.333344 4.47715 4.8105 0 10.3333 0C15.8562 0 20.3333 4.47715 20.3333 10ZM11.2293 7.13868L4.49518 9.91325C3.31021 10.3757 4.00385 10.8092 4.00385 10.8092C4.00385 10.8092 5.01542 11.156 5.88247 11.4161C6.74952 11.6763 7.21195 11.3872 7.21195 11.3872L11.2871 8.64157C12.7322 7.65891 12.3854 8.46816 12.0385 8.81498C11.2871 9.56643 10.0443 10.7514 9.00385 11.7052C8.54143 12.1098 8.77264 12.4566 8.97495 12.63C9.56309 13.1277 10.9479 14.0325 11.5775 14.4439C11.7523 14.5581 11.8689 14.6343 11.894 14.6531C12.0385 14.7687 12.8478 15.289 13.3391 15.1734C13.8304 15.0578 13.8882 14.393 13.8882 14.393L14.6108 9.85545C14.675 9.43006 14.7392 9.01358 14.7991 8.62521C14.9548 7.6151 15.0812 6.7951 15.1021 6.50284C15.1888 5.52019 14.1484 5.92481 14.1484 5.92481C14.1484 5.92481 11.894 6.84967 11.2293 7.13868Z" fill="white" fillOpacity="0.5"/>
                                            </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
                                                <path d="M19.7553 1.20733C19.4717 2.15135 18.891 2.86604 18.0802 3.41539C18.4682 3.39405 18.8401 3.30605 19.2121 3.20738C19.5867 3.10871 19.9453 2.97004 20.3333 2.81537C20.1995 3.04738 20.0523 3.22872 19.9052 3.41005C19.4663 3.9514 18.9686 4.43141 18.404 4.84476C18.3264 4.90076 18.2996 4.9621 18.3023 5.05543C18.3638 8.18618 17.3443 10.9356 15.3052 13.3037C14.1358 14.661 12.6962 15.6637 11.021 16.3171C9.41547 16.9438 7.74568 17.1864 6.02504 17.1064C4.39004 17.0318 2.84067 16.6237 1.37425 15.9011C1.01568 15.7251 0.66513 15.5304 0.333313 15.2984C1.40369 15.4024 2.45534 15.3197 3.49093 15.0344C4.52919 14.7464 5.47112 14.2744 6.35686 13.597C6.17757 13.5783 6.03307 13.5677 5.89125 13.5463C4.68975 13.365 3.74247 12.781 3.04672 11.789C2.8487 11.5063 2.6935 11.2023 2.57843 10.8796C2.53026 10.7436 2.53829 10.7329 2.68279 10.7569C3.19657 10.8449 3.705 10.8183 4.21076 10.7063C4.23751 10.7009 4.2616 10.6956 4.28836 10.6876C4.29371 10.6849 4.29906 10.6743 4.30977 10.6583C4.17329 10.6183 4.03682 10.5809 3.90302 10.5382C2.27337 10.0102 1.10131 8.43419 1.08525 6.75148C1.08258 6.59414 1.08258 6.59414 1.22708 6.66614C1.70607 6.90882 2.21183 7.05015 2.74701 7.09549C2.7818 7.09816 2.81926 7.09815 2.85405 7.09815C2.86208 7.09815 2.87011 7.09015 2.87814 7.08482C2.87814 7.03415 2.82997 7.02615 2.80053 7.00482C1.14948 5.79679 0.63837 3.56206 1.60706 1.76201C1.67664 1.63134 1.67931 1.63134 1.77029 1.74067C2.9156 3.09805 4.29639 4.14874 5.91533 4.88743C7.00979 5.38611 8.15777 5.70612 9.35392 5.84479C9.5787 5.87145 9.80615 5.88479 10.0336 5.90879C10.1433 5.92212 10.1594 5.88745 10.138 5.78345C9.80883 4.11674 10.5313 2.43136 11.9603 1.52467C13.5873 0.495307 15.6906 0.711313 17.0687 2.06068C17.1703 2.15935 17.2587 2.16202 17.3764 2.13535C18.1819 1.94868 18.9445 1.66067 19.6617 1.25533C19.6938 1.23933 19.7232 1.22333 19.7553 1.20733Z" fill="white" fillOpacity="0.5"/>
                                            </svg>}
                                    </Link>;
        })}
                            </div>

                        </div>
                    </div>
                    <div className="mobile_view">
                        <p className='title_description'>
                            O<span style={{ textDecoration: 'underline' }}>2</span>T
                            {banner.description}
                        </p>
                        <Link className='nav_buy_button' prefetch={false} target={'_blank'} href={'https://www.bitmart.com/trade/en-US?symbol=O2T_USDT'}>
                            <button className='nav_buy_button'>{banner.cta}
                                <svg className='inner_arrow' xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                    <path d="M10.2112 1L17.2112 8L10.2112 15" stroke="#191919" strokeWidth="2"/>
                                    <path d="M17.5 8.00098H0" stroke="#191919" strokeWidth="2"/>
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>);
};
export default CoinBanner;

    async function __Next_Translate__getStaticProps__190081424aa__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/Banner/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424aa__ as getStaticProps }
  