
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import TopQuestions from "../../faqs/TopQuestions";
const CoinFaqs = ({ top_questions }) => {
    return (<section className='coin_faqs faqs' id='faqs'>
            <TopQuestions top_questions={top_questions}/>
        </section>);
};
export default CoinFaqs;

    async function __Next_Translate__getStaticProps__190081424e6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//coin/Faqs/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190081424e6__ as getStaticProps }
  