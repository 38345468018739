
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Features = ({ features }) => {
    const Item = ({ item }) => {
        return <li className='features_section_item'>
            <div className='item_icon'>
                <img className='item_icon_icon' src={`/img/pages/home/features/${item.icon}.svg`} alt={`Option2Trade feature ${item.title}`} width={32} height={32}/>
            </div>
            <h2 className='item_title'>{item.title}</h2>
        </li>;
    };
    return (<section className='features_section'>
            <div className="container">
                <ul className='features_section_items'>
                    {features.map(item => {
            return <Item key={item.icon} item={item}/>;
        })}
                </ul>
            </div>
        </section>);
};
export default Features;

    async function __Next_Translate__getStaticProps__1900814259c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Features/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1900814259c__ as getStaticProps }
  